import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";

import {
  Box,
  Button,
  FlexRow,
  // IlloWrapper,
  PrismicImage,
  PrismicLink,
  RichText,
  SectionWrapper,
} from "src/components";

const SliceOuter = (props) => (
  <StaticQuery
    query={graphql`
      {
        festivals: allPrismicFestival {
          edges {
            node {
              uid
              dataString
            }
          }
        }
      }
    `}
    render={(data) => (
      <PosterSlice festivals={data.festivals.edges} {...props} />
    )}
  />
);

function PosterSlice({
  colorMode,
  image,
  description,
  buttonLink,
  buttonText,
  festivals,
  scaling,
  secondaryButtonLink,
  secondaryButtonText,
  sliceFestival,
}) {
  const [festival, setFestival] = useState({});

  useEffect(() => {
    if (sliceFestival && sliceFestival.uid) {
      festivals.forEach((innerFestival) => {
        if (innerFestival.node.uid === sliceFestival.uid) {
          setFestival(JSON.parse(innerFestival.node.dataString));
        }
      });
    }
  }, []);

  if (image && image.dimensions && image.dimensions.width) {
    return (
      <Box
        position="relative"
        as="section"
        pt={scaling !== "Cover" ? [12, 12, 10, 10, 8] : [10, 0]}
        mt={scaling !== "Cover" ? 0 : [0, -10, -8]}
        overflow="hidden"
      >
        <Box display={["none", "none", "block"]} as="section" pt={[6, 7, 8]}>
          <PrismicImage image={image} imgStyle={{ objectFit: "cover" }} />
        </Box>
        <Box
          display={["block", "block", "none"]}
          as="section"
          pt={scaling !== "Cover" ? 0 : [9, 10, 11]}
          width={scaling !== "Cover" ? "100%" : ["110%", "140%"]}
          ml={scaling !== "Cover" ? 0 : ["-5%", "-20%"]}
        >
          <PrismicImage image={image} imgStyle={{ objectFit: "cover" }} />
        </Box>
        {(buttonText || (description && description[0])) && (
          <Box position={["relative", "absolute"]} bottom={0} left={0}>
            <SectionWrapper pb={[9, 7, 8]}>
              <Box width={1} maxWidth={960} mx="auto">
                <Box pt={5} textAlign={["center", "left"]}>
                  {/* {title && <H2 children={title} color="inherit" />} */}
                  {description && (
                    <RichText
                      children={description}
                      mt={3}
                      fontSize={[1, 2]}
                      color={
                        colorMode === "Dark" ? "text.reverse" : "text.default"
                      }
                    />
                  )}
                  <FlexRow flexDirection={["column", "row"]} mt={5}>
                    {buttonText && buttonLink && (
                      <Box width={["100%", "auto"]}>
                        <Button
                          children={buttonText}
                          as={PrismicLink}
                          link={buttonLink}
                          variant="default"
                          width={["100%", "auto"]}
                        />
                      </Box>
                    )}
                    {secondaryButtonText && secondaryButtonLink && (
                      <Box ml={[0, 2]} mt={[2, 0]} width={["100%", "auto"]}>
                        <Button
                          children={secondaryButtonText}
                          as={PrismicLink}
                          link={secondaryButtonLink}
                          variant="primary"
                          width={["100%", "auto"]}
                        />
                      </Box>
                    )}
                  </FlexRow>
                </Box>
              </Box>
            </SectionWrapper>
          </Box>
        )}
      </Box>
    );
  } else {
    return null;
  }
}

export default SliceOuter;
