import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { StaticQuery, graphql } from "gatsby";

import { Theme, RibbonCTA } from "src/components";

const SliceOuter = props => (
  <StaticQuery
    query={graphql`
      {
        festivals: allPrismicFestival {
          edges {
            node {
              uid
              dataString
            }
          }
        }
      }
    `}
    render={data => <SimpleSlice festivals={data.festivals.edges} {...props} />}
  />
);

function SimpleSlice({
  title,
  description,
  buttonText,
  buttonLink,
  festivals,
  sliceFestival
}) {
  const [festival, setFestival] = useState({});

  useEffect(() => {
    if (sliceFestival && sliceFestival.uid) {
      festivals.forEach(innerFestival => {
        if (innerFestival.node.uid === sliceFestival.uid) {
          setFestival(JSON.parse(innerFestival.node.dataString));
        }
      });
    }
  }, []);

  return (
    <ThemeProvider
      theme={Theme({
        primary: festival && festival.festival_color,
        secondary: festival && festival.festival_secondary_color,
        tertiary: festival && festival.festival_tertiary_color
      })}
    >
      <RibbonCTA
        heading={title}
        body={description}
        buttonText={buttonText}
        buttonLink={buttonLink}
        color="brand.primary"
        invert={true}
      />
    </ThemeProvider>
  );
}

export default SliceOuter;
