import React from "react";

import {
  Box,
  Button,
  FlexRow,
  H1,
  SectionWrapper,
  RichText,
  PrismicImage,
  PrismicLink,
} from "src/components";

const CircleCTASlice = ({
  background_color,
  text_color,
  colorMode,
  decoration,
  description,
  button_text,
  button_link,
  title,
  id,
  image,
  side,
}) => (
  <SectionWrapper id={id} py={[6, 7, 8]} bg={background_color} maxWidth={960}>
    <FlexRow flexDirection={["column", "column", "row"]} alignItems="center">
      {side === "Left" && (
        <Box width={[1, 1, 1 / 2]}>
          <Box maxWidth="80%" mx="auto" mb={[6, 6, 0]}>
            <div
              css={`
                position: relative;
                width: 100%;
                padding-bottom: 100%;
              `}
            >
              {decoration && (
                <Box
                  position="absolute"
                  top={"10%"}
                  left={"-25%"}
                  width="100%"
                  height="100%"
                >
                  <PrismicImage image={decoration} objectFit="cover" />
                </Box>
              )}
              {image && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                >
                  <PrismicImage
                    image={image}
                    imgStyle={{ objectFit: "cover", borderRadius: 99999 }}
                  />
                </Box>
              )}
            </div>
          </Box>
        </Box>
      )}
      <Box width={[1, 1, 1 / 2]}>
        <Box
          maxWidth={["100%", "100%", "70%"]}
          mx="auto"
          position="relative"
          css={`
            z-index: 1;
          `}
        >
          <H1
            as="h2"
            children={title}
            color={
              text_color
                ? text_color
                : colorMode === "Dark"
                ? "text.reverse"
                : "text.default"
            }
          />
          {description && (
            <RichText
              mt={5}
              fontSize={[1, 2]}
              color={
                text_color
                  ? text_color
                  : colorMode === "Dark"
                  ? "text.reverse"
                  : "text.default"
              }
              children={description}
            />
          )}
          {button_text && button_link && (
            <Box mt={5}>
              <Button
                as={PrismicLink}
                children={button_text}
                link={button_link}
                variant="primary"
              />
            </Box>
          )}
        </Box>
      </Box>
      {side === "Right" && (
        <Box width={[1, 1, 1 / 2]}>
          <Box maxWidth="80%" mx="auto" mt={[6, 6, 0]}>
            <div
              css={`
                position: relative;
                width: 100%;
                padding-bottom: 100%;
              `}
            >
              {decoration && (
                <Box
                  position="absolute"
                  top={"10%"}
                  left={"-25%"}
                  width="100%"
                  height="100%"
                >
                  <PrismicImage image={decoration} objectFit="cover" />
                </Box>
              )}
              {image && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                >
                  <PrismicImage
                    image={image}
                    imgStyle={{ objectFit: "cover", borderRadius: 99999 }}
                  />
                </Box>
              )}
            </div>
          </Box>
        </Box>
      )}
    </FlexRow>
  </SectionWrapper>
);

export default CircleCTASlice;
