import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import { cover } from "polished";

import { Link } from "gatsby";

import {
  Box,
  Button,
  FlexCol,
  FlexGrid,
  H1,
  H6,
  PrismicLink,
  PrismicImage,
  SectionWrapper,
  IlloWrapper,
  Theme,
} from "src/components";

const SliceOuter = (props) => (
  <StaticQuery
    query={graphql`
      {
        festivals: allPrismicFestival {
          edges {
            node {
              uid
              dataString
            }
          }
        }
      }
    `}
    render={(data) => <HeroSlice festivals={data.festivals.edges} {...props} />}
  />
);

function HeroSlice({
  colorMode,
  title,
  images,
  buttonText,
  buttonLink,
  sliceFestival,
  festivals,
}) {
  const [festival, setFestival] = useState({});

  useEffect(() => {
    if (sliceFestival && sliceFestival.uid) {
      festivals.forEach((innerFestival) => {
        if (innerFestival.node.uid === sliceFestival.uid) {
          setFestival(JSON.parse(innerFestival.node.dataString));
        }
      });
    }
  }, []);

  return (
    <Box
      position="relative"
      bg={[
        festival.festival_color
          ? festival.festival_color
          : colorMode === "Dark"
          ? "bg.reverse"
          : "bg.default",
        null,
        "initial",
      ]}
    >
      <Box
        position="absolute"
        top={"17.5%"}
        left={"33%"}
        right={0}
        bottom={"17.5%"}
        bg={
          festival.festival_secondary_color
            ? festival.festival_secondary_color
            : colorMode === "Dark"
            ? "#313131"
            : "bg.wash"
        }
      />
      <SectionWrapper maxWidth={900}>
        <Box
          py={[6, 7, 8]}
          bg={
            festival.festival_color
              ? festival.festival_color
              : colorMode === "Dark"
              ? "bg.reverse"
              : "bg.default"
          }
        >
          {title && (
            <>
              <H1
                children={title}
                textAlign="center"
                color={
                  festival.festival_color
                    ? "text.reverse"
                    : colorMode === "Dark"
                    ? "text.reverse"
                    : "text.default"
                }
                mb={[3, 4]}
                css={`
                  position: relative;
                `}
              />
            </>
          )}
          {images && (
            <Box position="relative">
              <FlexGrid
                justifyContent={["center", "center", "space-between"]}
                alignItems="center"
                gutterY={[0]}
                mx={[-4, -5, 8]}
                position="relative"
              >
                {images.map((image, index) => (
                  <FlexCol
                    mt={[-6, -4]}
                    width={["40%", "40%", "40%", 1 / 5]}
                    mr={2}
                    key={"artistBlockImage" + index}
                    as={Link}
                    to={
                      image.concert_event && image.concert_event.uid
                        ? "/" +
                          (image.concert_event.type === "concer"
                            ? "concert"
                            : image.concert_event.type) +
                          "/" +
                          image.concert_event.uid
                        : sliceFestival
                        ? "/" +
                          sliceFestival.uid +
                          "/artist/" +
                          (image.artist && image.artist.uid) +
                          "/"
                        : "/"
                    }
                  >
                    <IlloWrapper
                      ne={index % 6 === 6 ? 0 : undefined}
                      sw={
                        index % 8 === 0
                          ? 4
                          : index % 8 === 4
                          ? 2
                          : index % 8 === 5
                          ? 1
                          : undefined
                      }
                      w={index % 8 === 6 ? 1 : undefined}
                      e={index % 8 === 2 ? 0 : index % 8 === 7 ? 3 : undefined}
                      se={index % 8 === 2 ? 1 : undefined}
                      illustrations={festival.illlustrations}
                      size={1.9}
                      position="relative"
                    >
                      <Box
                        pb="100%"
                        bg="bg.wash"
                        ml={
                          index % 4 === 1
                            ? [-3, -4, -6]
                            : index % 4 === 3
                            ? [-3, -4, -6]
                            : -2
                        }
                        mr={
                          index % 4 === 0
                            ? [-3, -4, -6]
                            : index % 4 === 2
                            ? [-3, -4, -6]
                            : -2
                        }
                        mt={
                          index % 4 === 0
                            ? 6
                            : index % 4 === 1
                            ? 10
                            : index % 4 === 2
                            ? [10, 10, 3]
                            : index % 4 === 3
                            ? [3, 3, 8]
                            : -2
                        }
                        position="relative"
                      >
                        <PrismicImage
                          image={image.artist_image}
                          style={{ ...cover(), width: "100%", height: "100%" }}
                          hideAttribution={true}
                        />

                        <FlexCol
                          position="absolute"
                          top={
                            index % 8 === 1 ||
                            index % 8 === 3 ||
                            index % 8 === 5 ||
                            index % 8 === 7
                              ? "100%"
                              : 0
                          }
                          left={
                            index % 8 === 1 ||
                            index % 8 === 3 ||
                            index % 8 === 5 ||
                            index % 8 === 7
                              ? ""
                              : 0
                          }
                          right={
                            index % 8 === 1 ||
                            index % 8 === 3 ||
                            index % 8 === 5 ||
                            index % 8 === 7
                              ? 0
                              : ""
                          }
                          bg="bg.reverse"
                          style={
                            index % 8 === 1 ||
                            index % 8 === 3 ||
                            index % 8 === 5 ||
                            index % 8 === 7
                              ? {
                                  transform: "translate(32px, -125%)",
                                }
                              : {
                                  transform: "translate(-32px, 25%)",
                                }
                          }
                          justifyContent="center"
                          alignItems="center"
                        >
                          <H6
                            mx={[2]}
                            my={1}
                            children={image.label}
                            color={"text.reverse"}
                            css={`
                              font-size: 18px !important;
                              @media (max-width: 900px) {
                                font-size: 16px !important;
                              }
                              @media (max-width: 600px) {
                                font-size: 12px !important;
                              }
                            `}
                          />
                        </FlexCol>
                      </Box>
                    </IlloWrapper>
                  </FlexCol>
                ))}
              </FlexGrid>
            </Box>
          )}
          <Box>
            <FlexCol mt={[8, 9, 10]} alignItems="center">
              {buttonText && buttonLink && (
                <Box>
                  <Button
                    as={PrismicLink}
                    link={buttonLink}
                    children={buttonText}
                    variant={festival.festival_color ? "default" : "primary"}
                  />
                </Box>
              )}
            </FlexCol>
          </Box>
        </Box>
      </SectionWrapper>
    </Box>
  );
}

export default SliceOuter;
