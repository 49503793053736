import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";

import {
  Box,
  Button,
  IlloWrapper,
  PrismicLink,
  RichText,
  SectionWrapper
} from "src/components";

const SliceOuter = props => (
  <StaticQuery
    query={graphql`
      {
        festivals: allPrismicFestival {
          edges {
            node {
              uid
              dataString
            }
          }
        }
      }
    `}
    render={data => <VideoSlice festivals={data.festivals.edges} {...props} />}
  />
);

function VideoSlice({
  colorMode,
  video,
  description,
  buttonLink,
  buttonText,
  festivals,
  sliceFestival
}) {
  const [festival, setFestival] = useState({});

  useEffect(() => {
    if (sliceFestival && sliceFestival.uid) {
      festivals.forEach(innerFestival => {
        if (innerFestival.node.uid === sliceFestival.uid) {
          setFestival(JSON.parse(innerFestival.node.dataString));
        }
      });
    }
  }, []);

  return (
    <Box as="section" my={[8, 9, 10]}>
      <section
        css={`
        position: relative;
        z-index: 0;
        padding: 0 ${props => props.theme.margin};
        // Splits the image with a colored top and white bottom.
        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          left: 0;
          z-index: -1;
          height: 50%;
          // background-color: ${props => props.theme.colors.bg.default};
        }
      `}
      >
        <div
          css={`
            width: 100%;
            max-width: ${props => props.theme.maxWidth[1]};
            margin: 0 auto;
          `}
        >
          <IlloWrapper
            nw={2}
            e={3}
            sw={4}
            illustrations={festival.illlustrations}
            size={2.5}
          >
            <div
              css={`
                position: relative;
                padding-bottom: 53%; /* 16:9 */
                height: 0;
                background-color: #000;
                border-radius: 5px;
                overflow: hidden;
              `}
            >
              <iframe
                width="849"
                height="503"
                src={video + "?showinfo=0&rel=0"}
                frameborder="0"
                allowfullscreen
                css={`
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  vertical-align: middle;
                  border: none;
                  background: transparent;
                `}
              />
            </div>
          </IlloWrapper>
        </div>
      </section>
      <SectionWrapper>
        <Box width={1} maxWidth={960} mx="auto">
          <Box pt={5} textAlign="center">
            {/* {title && <H2 children={title} color="inherit" />} */}
            {description && (
              <RichText
                children={description}
                mt={3}
                fontSize={[1, 2]}
                color={colorMode === "Dark" ? "text.reverse" : "text.default"}
              />
            )}
            {buttonText && buttonLink && (
              <Box mt={5}>
                <Button
                  children={buttonText}
                  as={PrismicLink}
                  link={buttonLink}
                  variant="default"
                />
              </Box>
            )}
          </Box>
        </Box>
      </SectionWrapper>
    </Box>
  );
}

export default SliceOuter;
