import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { StaticQuery, graphql } from "gatsby";

import {
  Box,
  Button,
  FlexCol,
  H1,
  PrismicLink,
  PrismicImage,
  RichText,
  SectionWrapper,
  IlloWrapper,
  Theme
} from "src/components";

const SliceOuter = props => (
  <StaticQuery
    query={graphql`
      {
        festivals: allPrismicFestival {
          edges {
            node {
              uid
              dataString
            }
          }
        }
      }
    `}
    render={data => <HeroSlice festivals={data.festivals.edges} {...props} />}
  />
);

function HeroSlice({
  colorMode,
  title,
  image,
  description,
  buttonText,
  buttonLink,
  festivals
}) {
  const [festival, setFestival] = useState({});

  useEffect(() => {
    if (buttonLink) {
      if (
        buttonLink.link_type === "Document" &&
        buttonLink.type === "festival"
      ) {
        festivals.forEach(innerFestival => {
          if (innerFestival.node.uid === buttonLink.uid) {
            setFestival(JSON.parse(innerFestival.node.dataString));
          }
        });
      } else {
        if (buttonLink.url) {
          festivals.forEach(innerFestival => {
            if (buttonLink.url.indexOf(innerFestival.node.uid) !== -1) {
              setFestival(JSON.parse(innerFestival.node.dataString));
            }
          });
        }
      }
    }
  }, []);

  return (
    <ThemeProvider
      theme={Theme({
        primary: festival && festival.festival_color,
        secondary: festival && festival.festival_secondary_color,
        tertiary: festival && festival.festival_tertiary_color
      })}
    >
      <SectionWrapper
        pt={[8, 9, 10]}
        bg={colorMode === "Dark" ? "bg.reverse" : "bg.default"}
      >
        {title && (
          <>
            {festival.illlustrations && (
              <IlloWrapper
                w={1}
                se={4}
                illustrations={festival.illlustrations}
                size={2.5}
              >
                <H1
                  children={title}
                  textAlign="center"
                  color={colorMode === "Dark" ? "text.reverse" : "text.default"}
                />
              </IlloWrapper>
            )}
            {!festival.illlustrations && (
              <H1
                children={title}
                textAlign="center"
                color={colorMode === "Dark" ? "text.reverse" : "text.default"}
              />
            )}
          </>
        )}
        {image && (
          <PrismicImage
            image={image}
            style={{ minHeight: 300, height: "50vh" }}
          />
        )}
        <FlexCol p={[4, 5]} alignItems="center">
          {description && (
            <RichText
              fontSize={3}
              textAlign="center"
              color={colorMode === "Dark" ? "text.reverse" : "text.body"}
            >
              {description}
            </RichText>
          )}
          {buttonText && buttonLink && (
            <Box mt={[4, 5]} mb={[3, 4]}>
              <Button
                as={PrismicLink}
                link={buttonLink}
                children={buttonText}
                variant="primary"
              />
            </Box>
          )}
        </FlexCol>
      </SectionWrapper>
    </ThemeProvider>
  );
}

export default SliceOuter;
