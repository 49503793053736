import React, { useState, useRef, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { RichText as PrismicRichText } from "prismic-reactjs";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

import {
  Box,
  Button,
  FlexCol,
  FlexRow,
  H2,
  H6,
  Icon,
  PrismicImage,
  PrismicLink,
} from "src/components";
import { mdiArrowLeft, mdiArrowRight } from "@mdi/js";

SwiperCore.use([Navigation]);

const useSwiperRef = () => {
  const [wrapper, setWrapper] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    setWrapper(ref.current);
  }, []);

  return [wrapper, ref];
};

function ArticleBlockSlice({
  colorMode,
  items,
  // image,
  // description,
  // buttonLink,
  // buttonText,
  // festivals,
  // secondaryButtonLink,
  // secondaryButtonText,
  // sliceFestival,
}) {
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();

  return (
    <Box position="relative" as="section">
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        navigation={{
          prevEl,
          nextEl,
        }}
        loop={true}
        grabCursor={true}
        watchSlidesProgress={true}
        watchSlidesVisibility={true}
        // breakpoints={{
        //   // when window width is >= 650px
        //   650: {
        //     slidesPerView: 2,
        //   },
        //   // when window width is >= 768px
        //   768: {
        //     slidesPerView: 2,
        //   },
        //   // when window width is >= 990px
        //   990: {
        //     slidesPerView: 3,
        //   },
        // }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={`slide-${index}`}>
            <Box pb={["100%", "70%", "50%"]}>
              <PrismicImage
                image={item.data.image}
                objectFit="cover"
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  top: 0,
                  right: 0,
                }}
              />
            </Box>
            <FlexCol
              position="absolute"
              bg="black"
              top={0}
              left={0}
              width="100%"
              height="100%"
              style={{ opacity: 0.6 }}
            />
            <FlexCol
              position="absolute"
              top={0}
              left={0}
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <FlexCol
                position="relative"
                maxWidth={["70%", "50%"]}
                alignItems="center"
              >
                <H6
                  children="Featured Article"
                  textAlign="center"
                  color="text.reverse"
                />
                <H2
                  as="h2"
                  textAlign="center"
                  children={PrismicRichText.asText(item.data.title)}
                  color="text.reverse"
                  my={[4, 6]}
                />
                <FlexRow justify="center">
                  <Button as={PrismicLink} children="Read more" link={item} />
                </FlexRow>
              </FlexCol>
            </FlexCol>
          </SwiperSlide>
        ))}
      </Swiper>
      <Box position="absolute" top="50%" left={[6, 8, 10]} zIndex={2}>
        <button
          ref={prevElRef}
          css={`
            cursor: pointer;
            border: none;
            background-color: transparent;
            transform: translateY(-50%);
          `}
        >
          <Icon
            symbol={mdiArrowLeft}
            color="white"
            css={`
              width: 24px !important;
              height: 24px !important;
            `}
          />
        </button>
      </Box>
      <Box position="absolute" top="50%" right={[6, 8, 10]} zIndex={2}>
        <button
          ref={nextElRef}
          css={`
            cursor: pointer;
            border: none;
            background-color: transparent;
            transform: translateY(-50%);
          `}
        >
          <Icon
            symbol={mdiArrowRight}
            color="white"
            css={`
              width: 24px !important;
              height: 24px !important;
            `}
          />
        </button>
      </Box>
    </Box>
  );
}

const ArticleBlockSliceComp = (props) => (
  <StaticQuery
    query={graphql`
      {
        posts: allPrismicPost {
          edges {
            node {
              prismicId
              dataString
            }
          }
        }
        announcements: allPrismicAnnouncement {
          edges {
            node {
              prismicId
              dataString
            }
          }
        }
        press: allPrismicPress {
          edges {
            node {
              prismicId
              dataString
            }
          }
        }
      }
    `}
    render={(data) => {
      let finalItems = [];
      props.items.forEach((item) => {
        data.posts.edges.forEach((post) => {
          if (item.article.id === post.node.prismicId) {
            finalItems.push({
              ...item.article,
              data: JSON.parse(post.node.dataString),
            });
          }
        });
      });
      props.items.forEach((item) => {
        data.announcements.edges.forEach((post) => {
          if (item.article.id === post.node.prismicId) {
            finalItems.push({
              ...item.article,
              data: JSON.parse(post.node.dataString),
            });
          }
        });
      });
      props.items.forEach((item) => {
        data.press.edges.forEach((post) => {
          if (item.article.id === post.node.prismicId) {
            finalItems.push({
              ...item.article,
              data: JSON.parse(post.node.dataString),
            });
          }
        });
      });
      return <ArticleBlockSlice {...props} items={finalItems} />;
    }}
  />
);

export default ArticleBlockSliceComp;
