import React, { useState, useRef, useEffect } from "react";
import moment from "moment-timezone";
import { StaticQuery, graphql, Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { RichText as PrismicRichText } from "prismic-reactjs";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

import {
  Box,
  Button,
  FlexCol,
  FlexGrid,
  FlexRow,
  H2,
  H6,
  Icon,
  PrismicImage,
  PrismicLink,
  SectionWrapper,
  Text,
} from "src/components";
import { mdiArrowLeft, mdiArrowRight } from "@mdi/js";

SwiperCore.use([Navigation, Pagination]);

const useSwiperRef = () => {
  const [wrapper, setWrapper] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    setWrapper(ref.current);
  }, []);

  return [wrapper, ref];
};

function FeaturedEventsSlice({
  background_color,
  colorMode,
  id,
  items,
  title,
  // image,
  // description,
  // buttonLink,
  // buttonText,
  // festivals,
  // secondaryButtonLink,
  // secondaryButtonText,
  // sliceFestival,
}) {
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();

  return (
    <Box py={[8, 9, 10]}>
      <H2
        children={title}
        color={colorMode === "Dark" ? "text.reverse" : "text.default"}
        textAlign="center"
      />
      <Box position="relative" as="section">
        <Box display={["none", "none", "block"]}>
          <SectionWrapper maxWidth={960} py={[6, 7, 8]}>
            <FlexGrid gutterX={[4]} gutterY={[4]}>
              {items.map((item, index) => {
                if (index === 0) {
                  return (
                    <FlexCol width={1 / 1}>
                      <Box
                        position="relative"
                        bg="bg.default"
                        borderRadius={8}
                        height="100%"
                        style={{ overflow: "hidden" }}
                      >
                        <Box pb={["50%"]} position="relative">
                          <PrismicImage
                            image={
                              item.data.performer
                                ? item.data.performer.data.image
                                : item.data.image
                            }
                            objectFit="cover"
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              top: 0,
                              right: 0,
                            }}
                          />
                        </Box>
                        <FlexCol
                          position="absolute"
                          bg="black"
                          top={0}
                          left={0}
                          width="100%"
                          height="100%"
                          style={{ opacity: 0.4 }}
                        />

                        <FlexRow
                          position="absolute"
                          bottom={0}
                          left={0}
                          p={4}
                          width="100%"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box pr={4}>
                            <H2
                              children={PrismicRichText.asText(
                                item.data.editor_title
                                  ? item.data.editor_title
                                  : item.data.title
                              )}
                              color="text.reverse"
                              textAlign="left"
                            />
                            {(item.data.date ||
                              item.data.start_time ||
                              item.data.venue) && (
                              <Text
                                mt={4}
                                fontSize={[0, 1]}
                                color="text.reverse"
                              >
                                {(item.data.date || item.data.start_time) &&
                                  moment(
                                    item.data.date || item.data.start_time
                                  ).format("MMM D, YYYY")}
                                {(item.data.date || item.data.start_time) &&
                                  item.data.venue &&
                                  ` · `}
                                {item.data.venue ? item.data.venue : ""}
                              </Text>
                            )}
                          </Box>
                          <FlexRow justifyContent="end" mt="auto">
                            <Button
                              as={PrismicLink}
                              children="View event details"
                              link={item}
                            />
                          </FlexRow>
                        </FlexRow>
                      </Box>
                    </FlexCol>
                  );
                } else {
                  return (
                    <FlexCol width={1 / 2} height="auto">
                      <Box
                        bg="bg.default"
                        borderRadius={8}
                        style={{ overflow: "hidden" }}
                        height="100%"
                      >
                        <Box pb={["60%"]} position="relative">
                          <PrismicImage
                            image={
                              item.data.performer
                                ? item.data.performer.data.image
                                : item.data.image
                            }
                            objectFit="cover"
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              top: 0,
                              right: 0,
                            }}
                          />
                        </Box>

                        <FlexCol
                          position="relative"
                          maxWidth="80%"
                          mx="auto"
                          p={4}
                          alignItems="center"
                          height="100%"
                        >
                          <H2
                            children={PrismicRichText.asText(
                              item.data.editor_title
                                ? item.data.editor_title
                                : item.data.title
                            )}
                            color="text.primary"
                            textAlign="center"
                          />
                          {(item.data.date || item.data.venue) && (
                            <Text
                              my={4}
                              fontSize={[0, 1]}
                              color="text.primary"
                              textAlign="center"
                            >
                              {(item.data.date || item.data.start_time) &&
                                moment(
                                  item.data.date || item.data.start_time
                                ).format("MMM D, YYYY")}
                              {(item.data.date || item.data.start_time) &&
                                item.data.venue &&
                                ` · `}
                              {item.data.venue ? item.data.venue : ""}
                            </Text>
                          )}
                          <Button
                            as={PrismicLink}
                            children="View event details"
                            link={item}
                            width="100%"
                          />
                        </FlexCol>
                      </Box>
                    </FlexCol>
                  );
                }
              })}
            </FlexGrid>
            <FlexRow justifyContent="center" mt={6}>
              <Box>
                <Button
                  as={Link}
                  children="See all events"
                  to="/concerts"
                  variant="primary"
                  width="100%"
                />
              </Box>
            </FlexRow>
          </SectionWrapper>
        </Box>
        <Box
          display={["block", "block", "none"]}
          py={[6, 7, 8]}
          css={`
            > div > div > div {
              height: auto !important;
            }
          `}
        >
          <Swiper
            slidesPerView={1.1}
            spaceBetween={8}
            navigation={{
              prevEl,
              nextEl,
            }}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            loop={true}
            grabCursor={true}
            watchSlidesProgress={true}
            watchSlidesVisibility={true}
            // breakpoints={{
            //   // when window width is >= 650px
            //   650: {
            //     slidesPerView: 2,
            //   },
            //   // when window width is >= 768px
            //   768: {
            //     slidesPerView: 2,
            //   },
            //   // when window width is >= 990px
            //   990: {
            //     slidesPerView: 3,
            //   },
            // }}
          >
            {items.map((item, index) => (
              <SwiperSlide key={`slide-${index}`}>
                <Box
                  bg="bg.default"
                  ml={2}
                  borderRadius={8}
                  height="100%"
                  style={{ overflow: "hidden" }}
                >
                  <Box pb={["60%"]} position="relative">
                    <PrismicImage
                      image={
                        item.data.performer
                          ? item.data.performer.data.image
                          : item.data.image
                      }
                      objectFit="cover"
                      style={{
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        top: 0,
                        right: 0,
                      }}
                    />
                  </Box>

                  <FlexCol
                    position="relative"
                    maxWidth="80%"
                    mx="auto"
                    p={4}
                    alignItems="center"
                  >
                    <H2
                      children={PrismicRichText.asText(
                        item.data.editor_title
                          ? item.data.editor_title
                          : item.data.title
                      )}
                      color="text.primary"
                      textAlign="center"
                    />
                    {(item.data.date ||
                      item.data.start_time ||
                      item.data.venue) && (
                      <Text
                        my={4}
                        fontSize={[0, 1]}
                        color="text.primary"
                        textAlign="center"
                      >
                        {(item.data.date || item.data.start_time) &&
                          moment(item.data.date || item.data.start_time).format(
                            "MMM D, YYYY"
                          )}
                        {(item.data.date || item.data.start_time) &&
                          item.data.venue &&
                          ` · `}
                        {item.data.venue ? item.data.venue : ""}
                      </Text>
                    )}
                    <Button
                      as={PrismicLink}
                      children="View event details"
                      link={item}
                      width="100%"
                    />
                  </FlexCol>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
          <FlexRow mt={4} justifyContent="center">
            <div
              className="swiper-pagination"
              css={`
                > span {
                  background: white;
                  height: 10px;
                  width: 10px;
                  margin-right: 4px;
                }
              `}
            />
          </FlexRow>
          <Box width="80%" mx="auto" mt={6}>
            <Button
              as={Link}
              children="See all events"
              to="/concerts"
              variant="primary"
              width="100%"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const FeaturedEventsSliceComp = (props) => (
  <StaticQuery
    query={graphql`
      {
        artists: allPrismicArtist {
          edges {
            node {
              uid
              prismicId
              dataString
            }
          }
        }
        events: allPrismicEvent {
          edges {
            node {
              uid
              prismicId
              dataString
            }
          }
        }
        concerts: allPrismicConcer {
          edges {
            node {
              uid
              prismicId
              dataString
            }
          }
        }
      }
    `}
    render={(data) => {
      let finalItems = [];
      props.items.forEach((item) => {
        data.events.edges.forEach((post) => {
          if (item.event.id === post.node.prismicId) {
            finalItems.push({
              ...item.event,
              data: JSON.parse(post.node.dataString),
            });
          }
        });
      });
      props.items.forEach((item) => {
        data.concerts.edges.forEach((post) => {
          if (item.event.id === post.node.prismicId) {
            let innerData = JSON.parse(post.node.dataString);
            data.artists.edges.forEach((artist) => {
              if (innerData.performer.id === artist.node.prismicId) {
                innerData.performer.data = JSON.parse(artist.node.dataString);
              }
            });
            finalItems.push({
              ...item.event,
              data: innerData,
            });
          }
        });
      });
      return <FeaturedEventsSlice {...props} items={finalItems} />;
    }}
  />
);

export default FeaturedEventsSliceComp;
