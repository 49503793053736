import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import useResizeObserver from "use-resize-observer/polyfilled";

import {
  Box,
  Button,
  FlexCol,
  FlexGrid,
  H2,
  PrismicLink,
  PrismicImage,
  RichText,
  SectionWrapper,
  IlloWrapper,
} from "src/components";

const SliceOuter = (props) => (
  <StaticQuery
    query={graphql`
      {
        festivals: allPrismicFestival {
          edges {
            node {
              uid
              dataString
            }
          }
        }
      }
    `}
    render={(data) => (
      <SimpleSlice festivals={data.festivals.edges} {...props} />
    )}
  />
);

function SimpleSlice({
  festivals,
  color,
  textColor,
  colorMode,
  title,
  flip,
  image,
  description,
  buttonText,
  buttonLink,
  sliceFestival,
}) {
  const [festival, setFestival] = useState({});

  const {
    ref: contentRef,
    width: contentWidth = 1,
    height: contentHeight = 1,
  } = useResizeObserver();
  const {
    ref: imageRef,
    width: imageWidth = 1,
    height: imageHeight = 1,
  } = useResizeObserver();

  useEffect(() => {
    if (sliceFestival && sliceFestival.uid) {
      festivals.forEach((innerFestival) => {
        if (innerFestival.node.uid === sliceFestival.uid) {
          setFestival(JSON.parse(innerFestival.node.dataString));
        }
      });
    }
  }, []);

  return (
    <Box position="relative">
      <Box
        position="absolute"
        top={[128, "50%"]}
        width="100vw"
        height={[
          "100%",
          imageHeight - 128 > contentHeight ? "calc(100% - 128px)" : "100%",
        ]}
        bg={color}
        css={`
          transform: translateY(-50%);
          @media (max-width: 639px) {
            transform: translateY(0);
          }
        `}
      />
      <SectionWrapper
        data-component-id="imageCTA"
        position="relative"
        mt={[8, 9, 10]}
      >
        <Box position="relative" width={1} maxWidth={960} mx="auto" mb={[7, 9]}>
          <FlexGrid gutterX={[7, 7, 7, 9]} gutterY={[5]}>
            <FlexCol
              justifyContent="center"
              alignItems="center"
              width={[1, 1 / 2]}
              order={flip ? -1 : 1}
            >
              <Box width={1} as={PrismicLink} link={buttonLink}>
                {image && (
                  <IlloWrapper
                    ne={2}
                    se={3}
                    sw={4}
                    illustrations={festival.illlustrations}
                    size={2.5}
                  >
                    <div ref={imageRef}>
                      <PrismicImage
                        image={image}
                        imgStyle={{ objectFit: "contain" }}
                      />
                    </div>
                  </IlloWrapper>
                )}
              </Box>
            </FlexCol>
            <FlexCol
              justifyContent="center"
              alignItems={["center", "flex-start"]}
              width={[1, 1 / 2]}
            >
              <div ref={contentRef}>
                {title && (
                  <H2
                    children={title}
                    textAlign={["center", "left"]}
                    color={
                      textColor === "Dark"
                        ? "text.default"
                        : textColor === "Light"
                        ? "text.reverse"
                        : colorMode === "Dark"
                        ? "text.reverse"
                        : "text.default"
                    }
                  />
                )}
                {description && (
                  <Box mt={3} textAlign={["center", "left"]}>
                    <RichText
                      color={
                        textColor === "Dark"
                          ? "text.default"
                          : textColor === "Light"
                          ? "text.reverse"
                          : colorMode === "Dark"
                          ? "text.reverse"
                          : "text.default"
                      }
                    >
                      {description}
                    </RichText>
                  </Box>
                )}
                {buttonText && buttonLink && (
                  <FlexCol mt={5} alignItems={["center", "flex-start"]}>
                    <Button
                      children={buttonText}
                      as={PrismicLink}
                      link={buttonLink}
                      variant={
                        textColor === "Dark"
                          ? "defaultAlt"
                          : textColor === "Light"
                          ? "reverseAlt"
                          : colorMode === "Dark"
                          ? "reverseAlt"
                          : "defaultAlt"
                      }
                    />
                  </FlexCol>
                )}
              </div>
            </FlexCol>
          </FlexGrid>
        </Box>
      </SectionWrapper>
    </Box>
  );
}

export default SliceOuter;
