import React, { useState, useRef, useEffect } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { RichText as PrismicRichText } from "prismic-reactjs";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

import {
  Box,
  Button,
  FlexCol,
  FlexRow,
  H1,
  H2,
  H3,
  H6,
  Icon,
  PrismicImage,
  PrismicLink,
  SectionWrapper,
  Text,
} from "src/components";
import { mdiArrowLeft, mdiArrowRight } from "@mdi/js";

SwiperCore.use([Navigation, Pagination]);

const useSwiperRef = () => {
  const [wrapper, setWrapper] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    setWrapper(ref.current);
  }, []);

  return [wrapper, ref];
};

function LineupCarouselSlice({
  background_color,
  colorMode,
  id,
  items,
  title,
  decoration,
  text_color,
  festival,
  // image,
  // description,
  // buttonLink,
  // buttonText,
  // festivals,
  // secondaryButtonLink,
  // secondaryButtonText,
  // sliceFestival,
}) {
  const [activeLineup, setActiveLineup] = useState(0);

  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();

  return (
    <Box>
      <Box position="relative" as="section">
        <Box display={["none", "none", "block"]} bg={background_color}>
          <SectionWrapper maxWidth={960} py={[6, 7, 8]}>
            <H3
              children={title}
              color={text_color ? text_color : "text.default"}
            />
            <FlexRow
              flexDirection={["column", "column", "row"]}
              alignItems="center"
            >
              <FlexCol width={[1, 1, 1 / 2]}>
                <Box>
                  <H1
                    as="h2"
                    children={PrismicRichText.asText(
                      items[activeLineup].data.artist_name
                    )}
                    color="text.primary"
                  />
                  <Text
                    children={
                      PrismicRichText.asText(
                        items[activeLineup].data.bio
                      ).slice(0, 196) + "..."
                    }
                    color="text.primary"
                    mt={4}
                  />
                  {festival && festival.uid && (
                    <Box mt={4}>
                      <Button
                        variant="primary"
                        as={Link}
                        to={
                          "/" +
                          festival.uid +
                          "/artist/" +
                          items[activeLineup].uid
                        }
                        children="Learn more"
                      />
                    </Box>
                  )}
                </Box>
              </FlexCol>
              <Box width={[1, 1, 1 / 2]}>
                <Box
                  maxWidth="80%"
                  mx="auto"
                  mb={[6, 6, 0]}
                  position="relative"
                >
                  <Box maxWidth="80%" mx="auto" mb={[6, 6, 0]}>
                    <div
                      css={`
                        position: relative;
                        width: 100%;
                        padding-bottom: 100%;
                      `}
                    >
                      {decoration && (
                        <Box
                          position="absolute"
                          top={"10%"}
                          left={"-10%"}
                          width="100%"
                          height="100%"
                        >
                          <PrismicImage image={decoration} objectFit="cover" />
                        </Box>
                      )}
                      {items[activeLineup].data.image && (
                        <Box
                          position="absolute"
                          top={0}
                          left={0}
                          width="100%"
                          height="100%"
                          key={activeLineup}
                        >
                          <PrismicImage
                            image={items[activeLineup].data.image}
                            style={{ width: "100%", height: "100%" }}
                            imgStyle={{
                              objectFit: "cover",
                              borderRadius: 99999,
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </Box>
                      )}
                    </div>
                  </Box>
                </Box>
              </Box>
            </FlexRow>
            <Box mt={[8, 9, 10]}>
              <Box position="relative">
                <Swiper
                  slidesPerView={4}
                  spaceBetween={0}
                  navigation={{
                    prevEl,
                    nextEl,
                  }}
                  slideToClickedSlide={true}
                  loop={true}
                  grabCursor={true}
                  watchSlidesProgress={true}
                  watchSlidesVisibility={true}
                  onSlideChange={(swiper) => {
                    setActiveLineup(swiper.realIndex);
                  }}
                >
                  {items.map((item, index) => (
                    <SwiperSlide key={`slide-${index}`}>
                      <H6
                        maxWidth={160}
                        children={PrismicRichText.asText(item.data.artist_name)}
                        color={text_color ? text_color : "text.default"}
                        textAlign="left"
                        px={2}
                        style={{
                          opacity: index === activeLineup ? 1 : 0.5,
                          transition: "opacity 300ms ease-out",
                        }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <Box
                  position="absolute"
                  top="50%"
                  left="-48px"
                  zIndex={2}
                  style={{ transform: "translateY(-50%)" }}
                >
                  <button
                    ref={prevElRef}
                    css={`
                      cursor: pointer;
                      border: none;
                      background-color: transparent;
                      transform: translateY(-50%);
                    `}
                  >
                    <Icon
                      symbol={mdiArrowLeft}
                      color="black"
                      css={`
                        width: 24px !important;
                        height: 24px !important;
                      `}
                    />
                  </button>
                </Box>
                <Box
                  position="absolute"
                  top={0}
                  top="50%"
                  right="-48px"
                  style={{ transform: "translateY(-50%)" }}
                >
                  <button
                    ref={nextElRef}
                    css={`
                      cursor: pointer;
                      border: none;
                      background-color: transparent;
                      transform: translateY(-50%);
                    `}
                  >
                    <Icon
                      symbol={mdiArrowRight}
                      color="black"
                      css={`
                        width: 24px !important;
                        height: 24px !important;
                      `}
                    />
                  </button>
                </Box>
              </Box>
            </Box>
          </SectionWrapper>
        </Box>
        <Box
          display={["block", "block", "none"]}
          py={[6, 7, 8]}
          css={`
            > div > div > div {
              height: auto !important;
            }
          `}
        >
          <H2
            children={title}
            color={colorMode === "Dark" ? "text.reverse" : "text.default"}
            textAlign="center"
            mb={6}
          />
          <Swiper
            slidesPerView={1.1}
            spaceBetween={8}
            pagination={{ el: ".swiper-pagination", clickable: true }}
            loop={true}
            grabCursor={true}
            watchSlidesProgress={true}
            watchSlidesVisibility={true}
            // breakpoints={{
            //   // when window width is >= 650px
            //   650: {
            //     slidesPerView: 2,
            //   },
            //   // when window width is >= 768px
            //   768: {
            //     slidesPerView: 2,
            //   },
            //   // when window width is >= 990px
            //   990: {
            //     slidesPerView: 3,
            //   },
            // }}
          >
            {items.map((item, index) => (
              <SwiperSlide key={`slide-${index}`}>
                <Box
                  bg={background_color}
                  p={4}
                  ml={2}
                  borderRadius={8}
                  height="100%"
                >
                  <Box maxWidth={"90%"} mx="auto">
                    <Box pb={["100%"]} position="relative">
                      <PrismicImage
                        image={item.data.image}
                        objectFit="cover"
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          top: 0,
                          right: 0,
                        }}
                        imgStyle={{ objectFit: "cover", borderRadius: 99999 }}
                      />
                    </Box>
                  </Box>
                  <FlexCol position="relative" mt={4}>
                    <H2
                      children={PrismicRichText.asText(item.data.artist_name)}
                      color="text.primary"
                    />
                    <Text
                      children={
                        PrismicRichText.asText(item.data.bio).slice(0, 196) +
                        "..."
                      }
                      color="text.primary"
                      mt={4}
                    />
                  </FlexCol>
                  {festival && festival.uid && (
                    <Box mt={4}>
                      <Button
                        variant="primary"
                        as={Link}
                        to={"/" + festival.uid + "/artist/" + item.uid}
                        children="Learn more"
                      />
                    </Box>
                  )}
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
          <FlexRow mt={4} justifyContent="center">
            <div
              className="swiper-pagination"
              css={`
                > span {
                  background: white;
                  height: 10px;
                  width: 10px;
                  margin-right: 4px;
                }
              `}
            />
          </FlexRow>
        </Box>
      </Box>
    </Box>
  );
}

const LineupSliceComp = (props) => (
  <StaticQuery
    query={graphql`
      {
        artists: allPrismicArtist {
          edges {
            node {
              uid
              prismicId
              dataString
            }
          }
        }
      }
    `}
    render={(data) => {
      let finalItems = [];
      props.items.forEach((item) => {
        data.artists.edges.forEach((artist) => {
          if (item.artist.id === artist.node.prismicId) {
            finalItems.push({
              ...item.artist,
              data: JSON.parse(artist.node.dataString),
            });
          }
        });
      });
      return <LineupCarouselSlice {...props} items={finalItems} />;
    }}
  />
);

export default LineupSliceComp;
