import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";

import { Box, PrismicImage } from "src/components";

const SliceOuter = (props) => (
  <StaticQuery
    query={graphql`
      {
        festivals: allPrismicFestival {
          edges {
            node {
              uid
              dataString
            }
          }
        }
      }
    `}
    render={(data) => (
      <MediaSlice festivals={data.festivals.edges} {...props} />
    )}
  />
);

function MediaSlice({ image, festivals, sliceFestival }) {
  const [festival, setFestival] = useState({});

  useEffect(() => {
    if (sliceFestival && sliceFestival.uid) {
      festivals.forEach((innerFestival) => {
        if (innerFestival.node.uid === sliceFestival.uid) {
          setFestival(JSON.parse(innerFestival.node.dataString));
        }
      });
    }
  }, []);

  if (image && image.dimensions && image.dimensions.width) {
    return (
      <Box position="relative" as="section">
        <PrismicImage image={image} imgStyle={{ objectFit: "cover" }} />
      </Box>
    );
  } else {
    return null;
  }
}

export default SliceOuter;
