import React, { useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs";

import { Box, SEO } from "src/components";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import CountdownSlice from "./_components/_countdownSlice";
import HeroSlice from "./_components/_heroSlice";
import LineupSlice from "./_components/_lineupSlice";
import DoubleSlice from "./_components/_doubleSlice";
import SimpleSlice from "./_components/_simpleSlice";
import CollageSlice from "./_components/_collageSlice";
import ImageSlice from "./_components/_imageSlice";
import VideoSlice from "./_components/_videoSlice";
import ArtistSlice from "./_components/_artistSlice";
import PosterSlice from "./_components/_posterSlice";
import MediaSlice from "./_components/_mediaSlice";
import RichTextSlice from "./_components/_richTextSlice";
import CircleCTASlice from "./_components/_circleCTASlice";
import LineupCarouselSlice from "./_components/_lineupCarouselSlice";
import FeaturedEventsSlice from "./_components/_featuredEventsSlice";
import ArticleBlockSlice from "./_components/_articleBlockSlice";

function IndexPage({ colorMode, setInvert, setInvertLogo, home }) {
  useEffect(() => {
    if (colorMode === "Dark") {
      setInvert(true);
      setInvertLogo(true);
    } else {
      setInvert(false);
      setInvertLogo(false);
    }
  }, []);

  return (
    <>
      <SEO
        image={
          home.opengraph_image &&
          home.opengraph_image.url &&
          home.opengraph_image.url
        }
        imageAlt={
          home.opengraph_image &&
          home.opengraph_image.alt &&
          home.opengraph_image.alt
        }
        title={
          home.opengraph_title &&
          home.opengraph_title[0] &&
          home.opengraph_title[0].text
            ? RichText.asText(home.opengraph_title)
            : "Home"
        }
        description={
          home.opengraph_description &&
          home.opengraph_description[0] &&
          home.opengraph_description[0].text &&
          RichText.asText(home.opengraph_description)
        }
      />
      <Box
        // pt="200px"
        pb="128px"
        bg={colorMode === "Dark" ? "#202020" : "bg.default"}
      >
        {home.body.map((slice, index) => {
          switch (slice.slice_type) {
            case "hero_cta":
              return (
                <HeroSlice
                  colorMode={colorMode}
                  title={
                    slice.primary.title
                      ? RichText.asText(slice.primary.title)
                      : ""
                  }
                  image={slice.primary.image}
                  description={
                    slice.primary.description
                      ? RichText.render(
                          slice.primary.description,
                          linkResolver,
                          serializer
                        )
                      : ""
                  }
                  buttonText={slice.primary.button_text}
                  buttonLink={slice.primary.button_link}
                />
              );
            case "lineup_block":
              return (
                <LineupSlice
                  colorMode={colorMode}
                  festival={slice.primary.festival}
                  ticketLink={slice.primary.ticket_link}
                  callout={
                    slice.primary.callout
                      ? RichText.render(
                          slice.primary.callout,
                          linkResolver,
                          serializer
                        )
                      : ""
                  }
                  buttonText={slice.primary.button_text}
                  buttonLink={slice.primary.button_link}
                  buttonText2={slice.primary.button_text2}
                  buttonLink2={slice.primary.button_link2}
                />
              );
            case "double_cta":
              return (
                <DoubleSlice
                  colorMode={colorMode}
                  title={
                    slice.primary.title
                      ? RichText.asText(slice.primary.title)
                      : ""
                  }
                  image={slice.primary.image}
                  description={
                    slice.primary.description
                      ? RichText.render(
                          slice.primary.description,
                          linkResolver,
                          serializer
                        )
                      : ""
                  }
                  buttonText={slice.primary.button_text}
                  buttonLink={slice.primary.button_link}
                  socialButtons={slice.primary.show_social_links === "Yes"}
                  title2={
                    slice.primary.title2
                      ? RichText.asText(slice.primary.title2)
                      : ""
                  }
                  image2={slice.primary.image2}
                  description2={
                    slice.primary.description2
                      ? RichText.render(
                          slice.primary.description2,
                          linkResolver,
                          serializer
                        )
                      : ""
                  }
                  buttonText2={slice.primary.button_text2}
                  buttonLink2={slice.primary.button_link2}
                  socialButtons2={slice.primary.show_social_links2 === "Yes"}
                  sliceFestival={slice.primary.festival}
                />
              );
            case "poster_cta":
              return (
                <PosterSlice
                  colorMode={colorMode}
                  image={slice.primary.image}
                  description={
                    slice.primary.description
                      ? RichText.render(
                          slice.primary.description,
                          linkResolver,
                          serializer
                        )
                      : ""
                  }
                  buttonText={slice.primary.button_text}
                  buttonLink={slice.primary.button_link}
                  scaling={slice.primary.scaling}
                  secondaryButtonText={slice.primary.secondary_button_text}
                  secondaryButtonLink={slice.primary.secondary_button_link}
                  sliceFestival={slice.primary.festival}
                />
              );
            case "simple_cta":
              return (
                <SimpleSlice
                  colorMode={colorMode}
                  title={
                    slice.primary.title
                      ? RichText.asText(slice.primary.title)
                      : ""
                  }
                  image={slice.primary.image}
                  description={slice.primary.description}
                  buttonText={slice.primary.button_text}
                  buttonLink={slice.primary.button_link}
                  sliceFestival={slice.primary.festival}
                />
              );
            case "collage_cta":
              return (
                <CollageSlice
                  colorMode={colorMode}
                  {...(slice && slice.primary)}
                  items={slice.items}
                  sliceFestival={slice.primary.festival}
                />
              );
            case "image_cta":
              return (
                <ImageSlice
                  colorMode={colorMode}
                  title={
                    slice.primary.title
                      ? RichText.asText(slice.primary.title)
                      : ""
                  }
                  color={slice.primary.color}
                  textColor={slice.primary.text_color}
                  image={slice.primary.image.dimensions && slice.primary.image}
                  description={
                    slice.primary.description
                      ? RichText.render(
                          slice.primary.description,
                          linkResolver,
                          serializer
                        )
                      : ""
                  }
                  buttonText={slice.primary.button_text}
                  buttonLink={slice.primary.button_link}
                  flip={slice.primary.side !== "Left" ? true : false}
                  sliceFestival={slice.primary.festival}
                />
              );
            case "video_cta":
              return (
                <VideoSlice
                  colorMode={colorMode}
                  video={
                    slice.primary.video_link && slice.primary.video_link.url
                  }
                  description={
                    slice.primary.description
                      ? RichText.render(
                          slice.primary.description,
                          linkResolver,
                          serializer
                        )
                      : ""
                  }
                  buttonText={slice.primary.button_text}
                  buttonLink={slice.primary.button_link}
                  sliceFestival={slice.primary.festival}
                />
              );
            case "artists_block":
              return (
                <ArtistSlice
                  colorMode={colorMode}
                  title={
                    slice.primary.title
                      ? RichText.asText(slice.primary.title)
                      : ""
                  }
                  images={slice.items}
                  buttonText={slice.primary.button_text}
                  buttonLink={slice.primary.button_link}
                  sliceFestival={slice.primary.festival}
                />
              );
            case "media":
              return <MediaSlice image={slice.primary.image} />;
            case "rich_text":
              return (
                <RichTextSlice
                  colorMode={colorMode}
                  title={
                    slice.primary.title
                      ? RichText.asText(slice.primary.title)
                      : ""
                  }
                  text={
                    slice.primary.text
                      ? RichText.render(
                          slice.primary.text,
                          linkResolver,
                          serializer
                        )
                      : ""
                  }
                  buttonText={slice.primary.button_text}
                  buttonLink={slice.primary.button_link}
                  sliceFestival={slice.primary.festival}
                />
              );
            case "circle_cta":
              return (
                <CircleCTASlice
                  colorMode={colorMode}
                  {...(slice && slice.primary)}
                  title={
                    slice.primary.title
                      ? RichText.asText(slice.primary.title)
                      : ""
                  }
                  description={
                    slice.primary.description
                      ? RichText.render(
                          slice.primary.description,
                          linkResolver,
                          serializer
                        )
                      : ""
                  }
                />
              );
            case "countdown":
              return (
                <CountdownSlice
                  colorMode={colorMode}
                  {...(slice && slice.primary)}
                  title={
                    slice.primary.title
                      ? RichText.asText(slice.primary.title)
                      : ""
                  }
                  description={
                    slice.primary.description
                      ? RichText.render(
                          slice.primary.description,
                          linkResolver,
                          serializer
                        )
                      : ""
                  }
                />
              );
            case "lineup_carousel":
              return (
                <LineupCarouselSlice
                  colorMode={colorMode}
                  {...(slice && slice.primary)}
                  title={
                    slice.primary.title
                      ? RichText.asText(slice.primary.title)
                      : ""
                  }
                  items={slice.items}
                />
              );
            case "featured_events":
              return (
                <FeaturedEventsSlice
                  colorMode={colorMode}
                  {...(slice && slice.primary)}
                  title={
                    slice.primary.title
                      ? RichText.asText(slice.primary.title)
                      : ""
                  }
                  items={slice.items}
                />
              );
            case "article_block":
              return (
                <ArticleBlockSlice
                  colorMode={colorMode}
                  {...(slice && slice.primary)}
                  items={slice.items}
                />
              );
            default:
              return null;
          }
        })}
      </Box>
    </>
  );
}

const IndexPageOuter = ({ preview, ...rest }) => (
  <StaticQuery
    query={graphql`
      {
        home: prismicHome {
          dataString
        }
      }
    `}
    render={(data) => {
      let home = JSON.parse(data.home.dataString);
      if (preview) {
        home = preview;
      }
      return (
        <IndexPage
          home={home}
          colorMode={home.color_mode === "Dark theme" ? "Dark" : "Light"}
          {...rest}
        />
      );
    }}
  />
);

export default IndexPageOuter;
