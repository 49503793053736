import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

import {
  Box,
  Button,
  FlexCol,
  FlexRow,
  H1,
  H3,
  SectionWrapper,
  RichText,
  PrismicImage,
  PrismicLink,
} from "src/components";

const Timer = ({ timeLeft }) => {
  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <>{timerComponents.length ? timerComponents : <span>Time's up!</span>}</>
  );
};

function CountdownSlice({
  background_color,
  date,
  text_color,
  colorMode,
  decoration,
  description,
  button_text,
  button_link,
  title,
  id,
  image,
  side,
}) {
  const calculateTimeLeft = () => {
    let currentDate = moment();
    let countdownDate = moment(date);

    let timeLeft = {};

    if (countdownDate.diff(currentDate, "seconds") > 0) {
      timeLeft = {
        days: countdownDate.diff(currentDate, "days"),
        hours: countdownDate.diff(currentDate, "hours") % 24,
        minutes: countdownDate.diff(currentDate, "minutes") % 60,
        seconds: countdownDate.diff(currentDate, "seconds") % 60,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <SectionWrapper id={id} py={[6, 7, 8]} bg={background_color} maxWidth={960}>
      <FlexRow flexDirection={["column", "column", "row"]} alignItems="center">
        <Box width="100%">
          <FlexCol
            alignItems="center"
            maxWidth={["100%", "100%", "70%"]}
            mx="auto"
            position="relative"
            css={`
              z-index: 1;
            `}
          >
            <H3
              textAlign="center"
              children={title}
              color={
                text_color
                  ? text_color
                  : colorMode === "Dark"
                  ? "text.reverse"
                  : "text.default"
              }
            />
            <H1
              as="h2"
              mt={3}
              textAlign="center"
              color={
                text_color
                  ? text_color
                  : colorMode === "Dark"
                  ? "text.reverse"
                  : "text.default"
              }
              style={{ fontSize: "3rem" }}
              fontSize={[1]}
            >
              <Timer timeLeft={timeLeft} />
            </H1>

            <Box width={[1, 1, 1 / 2]} my={[6, 6, 7]}>
              <Box maxWidth="80%" mx="auto">
                <div
                  css={`
                    position: relative;
                    width: 100%;
                    padding-bottom: 100%;
                  `}
                >
                  {decoration && (
                    <Box
                      position="absolute"
                      top={"10%"}
                      left={"-25%"}
                      width="100%"
                      height="100%"
                    >
                      <PrismicImage image={decoration} objectFit="cover" />
                    </Box>
                  )}
                  {image && (
                    <Box
                      position="absolute"
                      top={0}
                      left={0}
                      width="100%"
                      height="100%"
                    >
                      <PrismicImage
                        image={image}
                        imgStyle={{ objectFit: "cover", borderRadius: 99999 }}
                      />
                    </Box>
                  )}
                </div>
              </Box>
            </Box>
            {description && (
              <RichText
                mt={5}
                textAlign="center"
                fontSize={[2, 3]}
                color={
                  text_color
                    ? text_color
                    : colorMode === "Dark"
                    ? "text.reverse"
                    : "text.default"
                }
                children={description}
              />
            )}
            {button_text && button_link && (
              <Box mt={5}>
                <Button
                  as={PrismicLink}
                  children={button_text}
                  link={button_link}
                  variant="primary"
                />
              </Box>
            )}
          </FlexCol>
        </Box>
      </FlexRow>
    </SectionWrapper>
  );
}

export default CountdownSlice;
