import React, { useState, useEffect } from "react";
import styled, { keyframes, ThemeProvider } from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import moment from "moment-timezone";
import { RichText as PrismicRichText } from "prismic-reactjs";
import { rgba } from "polished";

import {
  Box,
  Button,
  FlexCol,
  H4,
  IlloWrapper,
  PrismicImage,
  PrismicLink,
  RadioButton,
  RichText,
  SectionWrapper,
  Text,
  Theme,
} from "src/components";
const openImage = keyframes`
  from {
    transform: translate3d(0, 30px, 0);
    opacity: 0;
  }
  to{
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

const closeImage = keyframes`
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 10px, 0);
    opacity: 0;
  }
`;

const LineupImageWrapper = styled.div`
  opacity: 0;
  animation: ${openImage} 1000ms ${(props) => props.theme.easing} forwards
    ${(props) => props.delay}ms;

  &.fade {
    animation: ${closeImage} 500ms ${(props) => props.theme.easing} forwards;
  }
`;

const dayLabels = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

function LineupSlice({
  colorMode,
  festival,
  schedule,
  buttonText,
  buttonText2,
  buttonLink,
  buttonLink2,
  callout,
}) {
  const [filter, setFilter] = useState(0);
  const [images, setImages] = useState([]);

  const [days] = useState(() => {
    let days = [];
    schedule.forEach((scheduleItem) => {
      if (scheduleItem.primary.start_time) {
        let date = moment(scheduleItem.primary.start_time)
          .tz("America/Edmonton")
          .dayOfYear();
        let day =
          dayLabels[
            moment(scheduleItem.primary.start_time).tz("America/Edmonton").day()
          ];
        let found = false;
        days.forEach((day) => {
          if (day.date === date) {
            found = true;
          }
        });
        if (!found) {
          days.push({ label: day, date: date });
        }
      }
    });
    days = days.sort((a, b) => {
      return a.date - b.date;
    });
    if (days[0]) {
      setFilter(days[0].date);
    }
    return days;
  });

  function getPlacement(position) {
    let left = 0;
    let top = 0;
    const imageVariance = 4;
    if (position === 0) {
      left = 8 + Math.floor(Math.random() * Math.floor(imageVariance));
      top = 0 + Math.floor(Math.random() * Math.floor(imageVariance));
    }
    if (position === 4) {
      left = -3 + Math.floor(Math.random() * Math.floor(imageVariance));
      top = 24 + Math.floor(Math.random() * Math.floor(imageVariance));
    }
    if (position === 1) {
      left = 65 + Math.floor(Math.random() * Math.floor(imageVariance));
      top = 0 + Math.floor(Math.random() * Math.floor(imageVariance));
    }
    if (position === 5) {
      left = 82 + Math.floor(Math.random() * Math.floor(imageVariance));
      top = 22 + Math.floor(Math.random() * Math.floor(imageVariance));
    }
    if (position === 2) {
      left = 17 + Math.floor(Math.random() * Math.floor(imageVariance));
      top = 78 + Math.floor(Math.random() * Math.floor(imageVariance));
    }
    if (position === 6) {
      left = -2 + Math.floor(Math.random() * Math.floor(imageVariance));
      top = 55 + Math.floor(Math.random() * Math.floor(imageVariance));
    }
    if (position === 3) {
      left = 65 + Math.floor(Math.random() * Math.floor(imageVariance));
      top = 72 + Math.floor(Math.random() * Math.floor(5));
    }
    if (position === 7) {
      left = 84 + Math.floor(Math.random() * Math.floor(imageVariance));
      top = 58 + Math.floor(Math.random() * Math.floor(5));
    }
    if (position === 8) {
      left = 16 + Math.floor(Math.random() * Math.floor(imageVariance));
      top = 40 + Math.floor(Math.random() * Math.floor(5));
    }
    if (position === 9) {
      left = 68 + Math.floor(Math.random() * Math.floor(imageVariance));
      top = 37 + Math.floor(Math.random() * Math.floor(5));
    }
    return { left: left, top: top };
  }

  useEffect(() => {
    function changeImages(images, replace) {
      let newImages = [];
      if (replace && images) {
        newImages = JSON.parse(JSON.stringify(images));
      }
      const imageVariance = 4;
      while (newImages.length < 10) {
        let scheduleTarget = Math.floor(
          Math.random() * Math.floor(schedule.length)
        );
        if (schedule[scheduleTarget].performerData) {
          if (
            schedule[scheduleTarget].performerData.image &&
            // moment(scheduleItem.primary.start_time).date() === filter &&
            schedule[scheduleTarget].performerData.image.url
            // scheduleItem.primary.featured
          ) {
            let found = false;
            newImages.forEach((image) => {
              if (image.uid === schedule[scheduleTarget].performerData.uid) {
                found = true;
              }
            });
            if (!found) {
              let height =
                14 + Math.floor(Math.random() * Math.floor(imageVariance));
              let width =
                14 + Math.floor(Math.random() * Math.floor(imageVariance));
              let zIndex = Math.floor(Math.random() * Math.floor(20));
              let left = getPlacement(
                replace ? replace : newImages.length
              ).left;
              let top = getPlacement(replace ? replace : newImages.length).top;
              let delay = 0 + Math.floor(Math.random() * Math.floor(400));
              newImages.push({
                left: left,
                top: top,
                height: height,
                width: width,
                image: schedule[scheduleTarget].performerData.image,
                uid: schedule[scheduleTarget].performerData.uid,
                zIndex: zIndex,
                delay: delay,
              });
              replace = false;
            }
          }
        }
      }
      setImages(newImages);
    }
    changeImages();
  }, [filter]);

  return (
    <ThemeProvider
      theme={Theme({
        primary: festival.festival_color,
        secondary: festival.festival_secondary_color,
        tertiary: festival.festival_tertiary_color,
      })}
    >
      <>
        <div>
          <FlexCol alignItems="center">
            <Box
              px={7}
              py={5}
              textAlign="center"
              bg="bg.wash"
              bg={colorMode === "Dark" ? rgba("#fff", 0.2) : "bg.wash"}
            >
              <H4
                color={colorMode === "Dark" ? "text.reverse" : "text.default"}
              >
                {moment(festival.start_day).format("MMMM DD") +
                  "–" +
                  moment(festival.end_day).format("DD, YYYY")}
              </H4>
            </Box>
          </FlexCol>
          <FlexCol
            position="relative"
            justifyContent="center"
            alignItems="center"
            width={1}
            height="325px"
            display={["flex", "flex", null, "none"]}
            my={6}
          >
            {images.slice(0, 4).map((image, index) => (
              <LineupImageWrapper
                key={"lineupImage" + image.uid}
                style={{
                  position: "absolute",
                  top: image.top / 1.25 + "%",
                  left: image.left / 1.25 + "%",
                  zIndex: image.zIndex,
                  height: image.height * 2.25 + "%",
                  width: image.width * 2.25 + "%",
                }}
                delay={image.delay}
                className={image.fade ? "fade" : ""}
              >
                <Link to={"/" + festival.uid + "/artist/" + image.uid}>
                  <IlloWrapper
                    illustrations={festival.illlustrations}
                    e={index === 0 && 2}
                    w={index === 1 && 1}
                    se={index === 2 && 4}
                    size={1.5}
                  >
                    <PrismicImage
                      image={image.image}
                      sizes="200px"
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.2) 0px 1rem 2.5rem",
                      }}
                    />
                  </IlloWrapper>
                </Link>
              </LineupImageWrapper>
            ))}
          </FlexCol>
          <FlexCol
            position="relative"
            justifyContent="center"
            alignItems="center"
            width={1}
            height={["initial", null, "100vh"]}
          >
            <Box display={["none", "none", null, "block"]}>
              {images.map((image, index) => (
                <LineupImageWrapper
                  key={filter + "lineupImage" + image.uid}
                  style={{
                    position: "absolute",
                    top: image.top + "%",
                    left: image.left + "%",
                    zIndex: image.zIndex,
                    height: image.height + "%",
                    width: image.width + "%",
                  }}
                  delay={image.delay}
                  className={image.fade ? "fade" : ""}
                >
                  <Link to={"/" + festival.uid + "/artist/" + image.uid}>
                    <IlloWrapper
                      illustrations={festival.illlustrations}
                      illustrations={festival.illlustrations}
                      se={index === 0 && 3}
                      sw={index === 10 && 0}
                      w={(index === 4 || index == 7) && 2}
                      e={(index === 5 || index === 9) && 4}
                      n={index === 6 && 1}
                      size={2.5}
                    >
                      <PrismicImage
                        image={image.image}
                        sizes="25vw"
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.2) 0px 1rem 2.5rem",
                        }}
                      />
                    </IlloWrapper>
                  </Link>
                </LineupImageWrapper>
              ))}
            </Box>
            <SectionWrapper>
              <FlexCol
                position={["static", null, "absolute"]}
                top="50%"
                left="50%"
                zIndex={100}
                justifyContent="center"
                alignItems="center"
                width={1}
                maxWidth={420}
                mx="auto"
                p={[0, null, 5]}
                css={`
                  transform: translate3d(-50%, -50%, 0);
                  @media (max-width: 52em) {
                    margin-top: 32px;
                    transform: initial;
                  }
                `}
              >
                <Box>
                  <RadioButton
                    options={days}
                    value={filter}
                    valueProp="date"
                    labelProp="label"
                    onChange={setFilter}
                  />
                </Box>
                <FlexCol
                  justifyContent="center"
                  css={`
                    height: 100px;
                    min-height: 460px;
                    @media (max-width: 52em) {
                      min-height: 320px;
                    }
                  `}
                >
                  {schedule
                    .sort((a, b) => {
                      if (
                        (a.primary.start_time &&
                          moment(a.primary.start_time).unix()) <
                        (b.primary.start_time &&
                          moment(b.primary.start_time).unix())
                      )
                        return -1;
                      if (
                        (a.primary.start_time &&
                          moment(a.primary.start_time).unix()) >
                        (b.primary.start_time &&
                          moment(b.primary.start_time).unix())
                      )
                        return 1;
                      return 0;
                    })
                    .map((scheduleItem, index) => {
                      if (scheduleItem.performerData) {
                        if (
                          scheduleItem.performerData.artist_name &&
                          scheduleItem.primary.start_time &&
                          scheduleItem.slice_type === "concert"
                        ) {
                          if (
                            moment(scheduleItem.primary.start_time)
                              .tz("America/Edmonton")
                              .dayOfYear() === filter &&
                            scheduleItem.primary.featured === "Yes"
                          ) {
                            return (
                              <Box mt={1} key={"artist" + index}>
                                <Text
                                  fontSize={[2, 3]}
                                  fontWeight={600}
                                  textAlign="center"
                                  color={
                                    colorMode === "Dark"
                                      ? "text.reverse"
                                      : "text.default"
                                  }
                                >
                                  <Link
                                    children={PrismicRichText.asText(
                                      scheduleItem.performerData.artist_name
                                    )}
                                    to={
                                      "/" +
                                      festival.uid +
                                      "/artist/" +
                                      scheduleItem.primary.performer.uid
                                    }
                                  />
                                </Text>
                              </Box>
                            );
                          } else {
                            return null;
                          }
                        } else {
                          return null;
                        }
                      } else {
                        return null;
                      }
                    })}
                </FlexCol>
                <FlexCol width={1} maxWidth={256} mx="auto">
                  {buttonText && buttonLink && (
                    <Box>
                      <Button
                        children={buttonText}
                        as={PrismicLink}
                        link={buttonLink}
                        to={"/" + festival.uid + "/lineup"}
                        width={1}
                      />
                    </Box>
                  )}
                  {buttonText2 && buttonLink2 && (
                    <Box mt={2}>
                      <Button
                        children={buttonText2}
                        as={PrismicLink}
                        link={buttonLink2}
                        width={1}
                        variant="primary"
                      />
                    </Box>
                  )}
                </FlexCol>
                {callout && (
                  <Box mt={4}>
                    <RichText fontSize={0} fluid textAlign="center">
                      {callout}
                    </RichText>
                  </Box>
                )}
              </FlexCol>
            </SectionWrapper>
          </FlexCol>
        </div>
      </>
    </ThemeProvider>
  );
}

const LineupSliceComp = (props) => (
  <StaticQuery
    query={graphql`
      {
        festivals: allPrismicFestival {
          edges {
            node {
              uid
              dataString
            }
          }
        }
        artists: allPrismicArtist {
          edges {
            node {
              uid
              prismicId
              dataString
            }
          }
        }
      }
    `}
    render={(data) => {
      let festival = {};
      if (data.festivals) {
        data.festivals.edges.forEach((festivalMatch) => {
          if (festivalMatch.node.uid === props.festival.uid) {
            festival = JSON.parse(festivalMatch.node.dataString);
            festival.uid = festivalMatch.node.uid;
          }
        });
      }
      if (festival.schedule) {
        festival.schedule.forEach((lineupItem) => {
          if (lineupItem.primary.performer) {
            data.artists.edges.forEach((artist) => {
              if (artist.node.prismicId === lineupItem.primary.performer.id) {
                lineupItem.performerData = JSON.parse(artist.node.dataString);
                lineupItem.performerData.uid = artist.node.uid;
              }
            });
          }
        });
      }
      return (
        <LineupSlice
          {...props}
          festival={festival}
          schedule={festival.schedule}
        />
      );
    }}
  />
);

export default LineupSliceComp;
