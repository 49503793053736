import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import {
  mdiFacebookBox,
  mdiTwitter,
  mdiInstagram,
  mdiSnapchat,
  mdiYoutube,
} from "@mdi/js";
import { hideVisually, rgba } from "polished";

import {
  Box,
  Button,
  FlexCol,
  FlexGrid,
  H2,
  Icon,
  IlloWrapper,
  PrismicLink,
  PrismicImage,
  RichText,
  SectionWrapper,
  Text,
  Theme,
} from "src/components";

const SocialButtons = ({ navigation }) => (
  <>
    {navigation.facebook && navigation.facebook.url && (
      <SocialButton
        icon={mdiFacebookBox}
        label={`Facebook`}
        link={navigation.facebook}
      />
    )}
    {navigation.twitter && navigation.twitter.url && (
      <SocialButton
        icon={mdiTwitter}
        label={`Twitter`}
        link={navigation.twitter}
      />
    )}
    {navigation.instagram && navigation.instagram.url && (
      <SocialButton
        icon={mdiInstagram}
        label={`Instagram`}
        link={navigation.instagram}
      />
    )}
    {navigation.snapchat && navigation.snapchat.url && (
      <SocialButton
        icon={mdiSnapchat}
        label={`Snapchat`}
        link={navigation.snapchat}
      />
    )}
    {navigation.youtube && navigation.youtube.url && (
      <SocialButton
        icon={mdiYoutube}
        label={`YouTube`}
        link={navigation.youtube}
      />
    )}
  </>
);

const SocialButton = ({ icon, label, link }) => (
  <PrismicLink link={link}>
    <div
      css={`
        width: 40px;
        div {
          transition: transform 250ms ${(props) => props.theme.easing};
        }
        svg {
          transition: opacity 125ms ${(props) => props.theme.easing};
        }
        @media not all and (hover: none) {
          &:hover {
            div {
              transform: scale(0.95);
              transform-origin: center;
            }
            svg {
              opacity: 0.75;
            }
          }
        }
      `}
    >
      <div
        css={`
          position: relative;
          padding-bottom: 100%;
          border-radius: 9999px;
          color: ${(props) => props.theme.colors.text.reverse};
          background-color: ${(props) =>
            rgba(props.theme.colors.bg.default, 0.125)};
          cursor: pointer;
          transition: transform 500ms ${(props) => props.theme.easing};

          svg {
            transition: opacity 350ms ${(props) => props.theme.easing};
          }

          @media not all and (hover: none) {
            &:hover {
              transform: scale(0.9);
              transform-origin: center;

              svg {
                opacity: 0.5;
              }
            }
          }
        `}
      >
        <Icon
          path={icon}
          size={1.25}
          css={`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            fill: currentColor;
          `}
        />
        <Text
          children={label}
          css={`
            ${hideVisually()};
          `}
        />
      </div>
    </div>
  </PrismicLink>
);

const SliceOuter = (props) => (
  <StaticQuery
    query={graphql`
      {
        festivals: allPrismicFestival {
          edges {
            node {
              uid
              dataString
            }
          }
        }
        navigation: prismicNavigation {
          dataString
        }
      }
    `}
    render={(data) => (
      <DoubleSlice
        festivals={data.festivals.edges}
        navigationData={data.navigation}
        {...props}
      />
    )}
  />
);

function DoubleSlice({
  colorMode,
  title,
  image,
  description,
  buttonText,
  buttonLink,
  title2,
  image2,
  description2,
  buttonText2,
  buttonLink2,
  festivals,
  sliceFestival,
  socialButtons,
  socialButtons2,
  navigationData,
}) {
  const [festival, setFestival] = useState({});

  let navigation = JSON.parse(navigationData.dataString);

  useEffect(() => {
    if (sliceFestival && sliceFestival.uid) {
      festivals.forEach((innerFestival) => {
        if (innerFestival.node.uid === sliceFestival.uid) {
          setFestival(JSON.parse(innerFestival.node.dataString));
        }
      });
    }
  }, []);

  return (
    <ThemeProvider
      theme={Theme({
        primary: festival && festival.festival_color,
        secondary: festival && festival.festival_secondary_color,
        tertiary: festival && festival.festival_tertiary_color,
      })}
    >
      <>
        <Box
          mt={[7, null, 9]}
          color="text.reverse"
          bg={[
            "bg.reverse",
            null,
            colorMode === "Dark" ? "bg.reverse" : "bg.default",
          ]}
          css={`
            position: relative;
          `}
        >
          <div
            css={`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 70%;
              background-color: ${(props) => props.theme.colors.brand.primary};
              ${(props) => props.theme.mediaQueries.medium} {
                width: 50%;
                height: calc(100% + 96px);
              }
            `}
          />
          <SectionWrapper>
            <div
              css={`
                position: relative;
              `}
            >
              <FlexGrid gutterX={[5, 5, 7, 9]}>
                <FlexCol justifyContent="center" width={[1, null, 1 / 3]}>
                  <Box py={[8, 9, 10]} textAlign={["center", null, "left"]}>
                    {title && <H2 children={title} color="inherit" />}
                    {description && (
                      <RichText
                        children={description}
                        mt={3}
                        fontSize={[1, 2]}
                        color="inherit"
                      />
                    )}
                    {buttonText && buttonLink && !socialButtons && (
                      <Box mt={5}>
                        <Button
                          children={buttonText}
                          as={PrismicLink}
                          link={buttonLink}
                          variant="reverseAlt"
                        />
                      </Box>
                    )}
                    {socialButtons && (
                      <Box mt={5}>
                        <FlexGrid gutterX={[1]}>
                          <SocialButtons navigation={navigation} />
                        </FlexGrid>
                      </Box>
                    )}
                  </Box>
                </FlexCol>
                <FlexCol width={[1, null, 2 / 3]}>
                  <Box
                    mt={[0, null, 9]}
                    mb={[7, null, 9]}
                    mr={[0, null, "-7.5vw"]}
                    css={`
                      position: relative;
                      z-index: 1;
                    `}
                  >
                    {image && (
                      <>
                        {festival.illlustrations && (
                          <IlloWrapper
                            nw={2}
                            // sw={4}
                            se={5}
                            illustrations={festival.illlustrations}
                            size={3}
                          >
                            <PrismicImage image={image} />
                          </IlloWrapper>
                        )}
                        {!festival.illlustrations && (
                          <PrismicImage image={image} />
                        )}
                      </>
                    )}
                  </Box>
                </FlexCol>
              </FlexGrid>
            </div>
          </SectionWrapper>
        </Box>
        <Box
          mt={[-7, null, -9]}
          color="text.reverse"
          css={`
            position: relative;
          `}
        >
          <Box
            bg={colorMode === "Dark" ? "brand.secondary" : "bg.reverse"}
            css={`
              position: absolute;
              top: 0;
              right: 0;
              width: 100%;
              height: 70%;
              ${(props) => props.theme.mediaQueries.medium} {
                top: -96px;
                width: 50%;
                height: calc(100% + 96px);
              }
            `}
          />
          <SectionWrapper>
            <div
              css={`
                position: relative;
              `}
            >
              <FlexGrid gutterX={[5, 5, 7, 9]}>
                <FlexCol
                  justifyContent="center"
                  alignItems="center"
                  width={[1, null, 1 / 3]}
                >
                  <Box py={[8, 9, 10]} textAlign={["center", null, "left"]}>
                    {title2 && <H2 children={title2} color="inherit" />}
                    {description2 && (
                      <RichText
                        children={description2}
                        mt={3}
                        fontSize={[1, 2]}
                        color="inherit"
                      />
                    )}
                    {socialButtons2 && (
                      <Box mt={5}>
                        <FlexGrid gutterX={[1]}>
                          <SocialButtons navigation={navigation} />
                        </FlexGrid>
                      </Box>
                    )}
                    {buttonText2 && buttonLink2 && !socialButtons2 && (
                      <Box mt={5}>
                        <Button
                          children={buttonText2}
                          as={PrismicLink}
                          link={buttonLink2}
                          variant="reverseAlt"
                        />
                      </Box>
                    )}
                  </Box>
                </FlexCol>
                <FlexCol order={[1, null, -1]} width={[1, null, 2 / 3]}>
                  <Box
                    mt={[0, null, 9]}
                    mb={[7, null, 9]}
                    ml={[0, null, "-7.5vw"]}
                    css={`
                      position: relative;
                      z-index: 1;
                    `}
                  >
                    {image2 && (
                      <>
                        {festival.illlustrations && (
                          <IlloWrapper
                            w={2}
                            se={1}
                            size={3}
                            illustrations={festival.illlustrations}
                          >
                            <PrismicImage image={image2} />
                          </IlloWrapper>
                        )}

                        {!festival.illlustrations && (
                          <PrismicImage image={image2} />
                        )}
                      </>
                    )}
                  </Box>
                </FlexCol>
              </FlexGrid>
            </div>
          </SectionWrapper>
        </Box>
      </>
    </ThemeProvider>
  );
}

export default SliceOuter;
