import React, { useState, useEffect } from "react";
import { StaticQuery, graphql } from "gatsby";

import {
  Box,
  Button,
  FlexRow,
  H1,
  SectionWrapper,
  RichText,
  PrismicLink,
} from "src/components";

const SliceOuter = (props) => (
  <StaticQuery
    query={graphql`
      {
        festivals: allPrismicFestival {
          edges {
            node {
              uid
              dataString
            }
          }
        }
      }
    `}
    render={(data) => (
      <RichTextSlice festivals={data.festivals.edges} {...props} />
    )}
  />
);

function RichTextSlice({
  colorMode,
  text,
  buttonText,
  buttonLink,
  title,
  id,
  festivals,
  sliceFestival,
}) {
  const [festival, setFestival] = useState({});

  useEffect(() => {
    if (sliceFestival && sliceFestival.uid) {
      festivals.forEach((innerFestival) => {
        if (innerFestival.node.uid === sliceFestival.uid) {
          setFestival(JSON.parse(innerFestival.node.dataString));
        }
      });
    }
  }, []);

  return (
    <SectionWrapper id={id} py={[8, 9, 10]} maxWidth={960}>
      <FlexRow flexDirection={["column", "column", "row"]}>
        <Box width={["100%", "100%", 1 / 2]}>
          <Box maxWidth={["100%", "100%", "80%"]}>
            <H1
              as="h2"
              children={title}
              color={colorMode === "Dark" ? "text.reverse" : "text.default"}
            />
            {buttonText && buttonLink && (
              <Box mt={4}>
                <Button
                  as={PrismicLink}
                  children={buttonText}
                  link={buttonLink}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box width={[1, 1, 1 / 2]} mt={[4, 4, -3]}>
          {text && (
            <RichText
              children={text}
              color={colorMode === "Dark" ? "text.reverse" : "text.default"}
            />
          )}
        </Box>
      </FlexRow>
    </SectionWrapper>
  );
}

export default SliceOuter;
